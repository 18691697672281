const data = [
  {
    id: 1,
    title: 'What is the most featured item?',
    text: `No comfort do written conduct at prevent manners on. Celebrated contrasted discretion him sympathize
    her collecting occasional. Do answered bachelor occasion in of offended no concerns. Supply worthy
    warmth branch of no ye. Voice tried known to as my to. Though wished merits or be. Alone visit use
    these smart rooms ham. No waiting in on enjoyed placing it inquiry. In no impression assistance
    contrasted. Manners she wishing justice hastily new anxious. At discovery discourse departure objection
    we. Few extensive add delighted tolerably sincerity her. Law ought him least enjoy decay one quick
    court. Expect warmly its tended garden him esteem had remove off. Effects dearest staying now
    sixteen nor improve.`,
  },
  {
    id: 2,
    title: 'Other information',
    text: `No comfort do written conduct at prevent manners on. Celebrated contrasted discretion him sympathize
    her collecting occasional. Do answered bachelor occasion in of offended no concerns. Supply worthy
    warmth branch of no ye. Voice tried known to as my to. Though wished merits or be. Alone visit use
    these smart rooms ham. No waiting in on enjoyed placing it inquiry. In no impression assistance
    contrasted. Manners she wishing justice hastily new anxious. At discovery discourse departure objection
    we. Few extensive add delighted tolerably sincerity her. Law ought him least enjoy decay one quick
    court. Expect warmly its tended garden him esteem had remove off. Effects dearest staying now
    sixteen nor improve.`,
  },
  {
    id: 3,
    title: 'Last group',
    text: `No comfort do written conduct at prevent manners on. Celebrated contrasted discretion him sympathize
    her collecting occasional. Do answered bachelor occasion in of offended no concerns. Supply worthy
    warmth branch of no ye. Voice tried known to as my to. Though wished merits or be. Alone visit use
    these smart rooms ham. No waiting in on enjoyed placing it inquiry. In no impression assistance
    contrasted. Manners she wishing justice hastily new anxious. At discovery discourse departure objection
    we. Few extensive add delighted tolerably sincerity her. Law ought him least enjoy decay one quick
    court. Expect warmly its tended garden him esteem had remove off. Effects dearest staying now
    sixteen nor improve.`,
  },
];

export default data;
